/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available; please refresh.')
      // 如果新的 service worker 已經安裝，則不彈出提示
      if (registration.waiting) {
        showRefreshUI(registration)
      }
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

function showRefreshUI(registration) {
  console.log(registration)
  // 創建一個簡單的彈窗來提示用戶刷新
  let refreshing
  const toast = document.createElement('div')
  toast.style.position = 'fixed'
  toast.style.bottom = '20px'
  toast.style.left = '20px'
  toast.style.padding = '10px'
  toast.style.backgroundColor = '#333'
  toast.style.color = '#fff'
  toast.style.zIndex = '10000'
  toast.innerText = 'New content is available; click to refresh.'

  toast.addEventListener('click', () => {
    if (!refreshing) {
      refreshing = true
      window.location.reload()
    }
  })

  document.body.appendChild(toast)
}
