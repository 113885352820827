export const changelogs = [
  {
    "date": "2022-11-5",
    "html": "將所有FAQ翻成簡體字和英文",
    "type": "log"
  },
  {
    "date": "2022-11-24",
    "html": "將部份FAQ翻成簡體字和英文",
    "type": "log"
  },
  {
    "date": "2022-11-23",
    "html": "將首頁和介紹頁翻成簡體字和英文",
    "type": "log"
  },
  {
    "date": "2023-03-20",
    "html": "閉關中，即日起不接受何任何外包案件",
    "type": "log"
  },
  {
    "date": "2023-07-06",
    "html": "即日起，僅接受培力式案件。 何謂培力式案件？\n一般外包，過了保固就很難維俢。培力式案件中，除了協助您完成專案，也同時透過double-hacking的方式，協助您的團隊未來能自行維護",
    "type": "reset"
  },
  {
    "date": "2023-09-11",
    "html": `即日起，關閉靜修並<a href = "https://docs.google.com/document/d/14Y7mgIqtj9QXRu0wpcJix-Snf1CLsWh6A8b8zgNDWRM/edit?usp=sharing" target="_blank" rel="noopener noreferrer">陪伴家人及長輩</a>及研究<a href="https://github.com/bestian/edu-lang" target="_blank" rel="noopener noreferrer">教育元語言</a>。`,
    "type": "reset"
  },
  {
    "date": "2023-09-17",
    "html": `從臉書脫勾，將分享按鈕，改為複製網址。`,
    "type": "reset"
  },
  {
    "date": "2023-09-30",
    "html": `完成<a href="https://hackmd.io/owuiofYLRN6xvwcO8yjV1Q?view" target="_blank" rel="noopenner noreferrer">金剛經注釋</a>初稿。`,
    "type": "work"
  },
  {
    "date": "2023-10-07",
    "html": `出關，並開始在服務人時接受隨喜功德。<a href="https://hack.bestian.tw/gift" target="_blank" rel="noopenner noreferrer">詳見此</a>。`
  },
  {
    "date": "2023-10-08",
    "html": `完成<a href="https://chat.openai.com/share/b9f1dbdb-e423-49f8-87ad-b4c420343cae" target="_blank" rel="noopenner noreferrer">Line相簿與長期記憶</a>短文。`
  },
  {
    "date": "2023-10-13",
    "html": `完成<a href="https://bestian.github.io/q-spell/#/">佛門咒文集</a>改版。`
  },
  {
    "date": "2023-10-17",
    "html": `完成<a href="https://change.bestian.tw">掌中易經</a>改版。`
  },
  {
    "date": "2023-10-20",
    "html": `完成<a href="https://hack.bestian.tw/faq/">常見問題</a>以及<a href="https://change.bestian.tw">掌中易經</a>更新改版。`
  },
  {
    "date": "2023-10-22",
    "html": `更新<a href="https://hack.bestian.tw/comments/">培力時期如何提供給Bestian好評？</a>`
  },
  {
    "date": "2023-10-13",
    "html": `完成<a href="https://bestian.github.io/q-spell/#/">佛門咒文集</a>改版。`
  },
  // ...this.news
  {
    "date": "2023-10-24",
    "html": `完成<a href="https://docs.google.com/forms/d/e/1FAIpQLSeM3kZNx7LjTVBY2o856ZJINx5ZYD5b4renmAK2AEJhx5TJiw/viewform">自學入口網改版供需調查表</a>。花5分鐘即可填寫此問卷。`
  },
  {
    "date": "2023-10-24",
    "html": `完成<a href="https://bestian.medium.com/github%E5%92%8Chackmd%E6%98%AF%E4%BB%80%E9%BA%BC%E6%9D%B1%E6%9D%B1-06dd7269210d" target="_blank" rel="noopener norefferer">Github和HackMD的使用</a>短文`
  },
  {
    "date": "2023-10-24",
    "html": `完成<a href="https://diverse-8dw.pages.dev/">學習風格自我測驗</a>Beta版`
  },
  {
    "date": "2023-10-25",
    "html": `完成<a href="https://bestian.medium.com/github%E5%92%8Chackmd%E6%98%AF%E4%BB%80%E9%BA%BC%E6%9D%B1%E6%9D%B1-06dd7269210d">HackMD與Github是什麼東東？</a>草稿`
  },
  {
    "date": "2023-10-25",
    "html": `完成<a href="https://github.com/3dw/github-tutorial">Github入門教學</a>初稿`
  },
  {
    "date": "2023-11-11",
    "html": `自我休養，參與父母種菜接地氣。`
  },
  // {
  //  "date": "2023-11-21",
  //  "html": `商用課程<a href="https://futureplanet.com.tw/product/vue-bestian/" target="_blank" rel="noopener noreferrer">樹要長得多高，根要紮得多深-按步就班學習Vue的程式架構</a>上架。感謝未來星球平台。`
  //},
  {
    "date": "2023-11-23",
    "html": `感恩節前後，交流非營利的<a href="https://change.bestian.tw" target="_blank" rel="noopener noreferrer">掌中易經</a>。`
  },
  {
    "date": "2023-11-26",
    "html": `開始關注並參與開源社群「<a href="https://g0v.tw/" target="_blank" rel="noopener norefferer">零時政府</a>」的「個人隱私守護交流頻道」(<a href="https://slack.com/intl/zh-tw/" rel="noopener norefferer">slack</a> > g0v > #privacy)`
  },
  {
    "date": "2023-11-29",
    "html": `從菜地維護學習與植物「互利共生」`
  },
  {
    "date": "2023-11-30",
    "html": `順天應人，無甚要事，近況動態改為隔週寄送一次。`
  },
  {
    "date": "2023-12-03",
    "html": `網路小說<a href="https://novel.bestian.tw">《諸星之戰》</a>初步校對完成上網`
  },
  {
    "date": "2023-12-04",
    "html": `更新<a href="https://hack.bestian.tw/course">課程介紹</a>頁面`
  },
  { "date": "2023-12-11",
    "html": `重新開工`
  },
  {
    "date": "2023-12-14",
    "html": "更新<a href='https://ahmitaba.github.io/buddhist_name/#/'>永明佛寺念佛共修</a>為冬季"
  },
  {
    "date": "2024-01-31",
    "html": "閉關中，即日起不接受何任何外包案件",
    "type": "log"
  },
  {
    "date": "2024-04-17",
    "html": "完成<a href='https://hackstep.pages.dev'>Hackstep</a>知識分享平台初稿"
  },
  {
    "date": "2024-05-31",
    "html": "更新<a href='https://we.alearn.org.tw/'>自學2.0</a>"
  },
  {
    "date": "2024-06-20",
    "html": "更新<a href='https://math.alearn.org.tw/'>自由數學</a>"
  },
  {
    "date": "2024-06-22",
    "html": "整理<a href='https://www.youtube.com/watch?v=mJha4ls1MfM&list=PLebzuoh5ZI3LrVduRDqLcxCxlkruyDL27' target='_blank' rel='noopener norefferer'>Freemath自由數學的Podcast頻道</a>"
  },
  {
    "date": "2024-07-10",
    "html": "更新技能點和知識結構",
    "r": "trace"
  },
  {
    "date": "2024-07-11",
    "html": "新增<a href='https://playback.bestian.tw' target='_blank' rel='noopener norefferer'>Playback劇場</a>"
  },
  {
    "date": "2024-07-16",
    "html": "新增「贊助創作」頁面",
    "r": "sponsor"
  },
  {
    "date": "2024-07-16",
    "html": "製作Vue知識結構初稿",
    "r": "trace_vue"
  },
  { "date": "2024-09-17",
    "html": "更新技能點",
    "r": "courses"
  },
  {
    "date": "2024-11-11",
    "html": "更新<a href='https://map.alearn.org.tw' target='_blank' rel='noopener norefferer'>自學地圖，加入AI自學對話機器人</a>"
  }
]
